export const capitalise = (value?: string): string => {
  if (!value) {
    return ''
  }
  const [firstLetter, ...restLitters] = value.split('')

  return [firstLetter.toUpperCase(), ...restLitters].join('')
}

/**
 * Truncates the given string to a maximum number of words or length, if necessary.
 *
 * @param {string} str - The string to truncate.
 * @param {number} [maxAmountWords=15] - The maximum number of words that can be returned. Default is 15.
 * @param {number} [maxStringLength=100] - The maximum length of the string that can be returned. Default is 100.
 * @param {string} [suffix='...'] - The string to append to the end of the truncated string. Default is '...'.
 *
 * @returns {string} - The truncated string.
 */
export const truncateString = (
  str: string,
  maxAmountWords: number = 15,
  maxStringLength: number = 100,
  suffix: string = '...'
) => {
  if (str.length <= maxStringLength && str.split(' ').length <= maxAmountWords) {
    return str
  }
  return (
    str
      .split(' ')
      .reduce(
        (prev, curr, index) =>
          prev.length + curr.length <= maxStringLength - suffix.length && index <= maxAmountWords
            ? `${prev} ${curr}`
            : prev,
        ''
      ) + suffix
  )
}

export const compareStrings = (sample: string, ...strings: string[]) =>
  strings.every((value) => value.toLowerCase() === sample.toLowerCase())

export const camelCase = (str: string): string => {
  return str
    ?.replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
    .replace(/^[^a-zA-Z0-9]+/, '')
    .replace(/[^a-zA-Z0-9]/g, '')
    .replace(/^./, (match) => match.toLowerCase())
}

export const removeSpecificSymbols = (str: string) => str.replace(/[^\S\r\n\x21-\x7E\u00A0-\uFFFF ]/g, '')
