import { SpecialistPracticeOrSpecialistProfileWithLang } from '../types'

const getFirstLetterInUpperCase = (str: Record<string, string>, language: string) =>
  (str[language] || str.en)[0].toUpperCase() || ''

export const getAbbreviation = ({ practice, specialist, language }: SpecialistPracticeOrSpecialistProfileWithLang) => {
  if (practice) {
    return getFirstLetterInUpperCase(practice.name, language)
  }

  return (
    getFirstLetterInUpperCase(specialist.firstName, language) +
    getFirstLetterInUpperCase(specialist?.lastName, language)
  )
}
