export const transformToQuery = (
  data: Record<string, string | number | string[] | undefined | null | any[] | boolean>
) => {
  const params = new URLSearchParams()

  Object.keys(data).forEach((key) => {
    const value = data[key]
    if ((value && !Array.isArray(value)) || (value && Array.isArray(value) && value.length)) {
      if (Array.isArray(value)) {
        return value.forEach((item) => {
          if (item) {
            params.append(`${key}[]`, String(item))
          }
        })
      }
      if (key === 'keyword') {
        return params.append(key, `"${String(value)}"`)
      }
      return params.append(key, String(value))
    }
    return null
  })

  return params.toString()
}

export const parseQueryFromString = (url?: string) => {
  const withProtocol = url?.includes('http://') || url?.includes('https://')
  const parsedUrl = new URL(url ?? '', !withProtocol ? 'http://test.com' : undefined)

  return Object.fromEntries(parsedUrl.searchParams)
}

export const parseParamsFromPath = (path: string) => path.split('/').slice(1)
