import { useContext, useMemo } from 'react'
import { transformAddressToString, transformHomeCareAddressToString } from '../utils/address'
import { getYearsDiff } from '../utils/date'
import { getAbbreviation } from '../utils/getAbbreviation'
import { getPracticeContacts, mapSpecialistPracticesContacts } from '../utils/getContacts'
import {
  PracticeOrSpecialistProfileWithLang,
  PracticeProfile,
  SearchProfileData,
  SpecialistPractice,
  SpecialistProfile
} from '../types'
import { PracticePlans, SpecialistPlans } from '../constants/profile'
import { AppDataContext } from '../providers/AppData'
import { ProfileContext } from '../providers/AppData/Profile'
import { ContactDetailsProfile } from '../components/profiles/ProfileModal/types'
import { FilterType } from '../constants/search'
import { removeSpecificSymbols } from '../utils/strings'

export const unclaimedTags = [
  PracticePlans.NO_PLAN,
  PracticePlans.UNCLAIMED,
  SpecialistPlans.NO_PLAN,
  SpecialistPlans.UNCLAIMED
]

const entryTags = [PracticePlans.ENTRY, SpecialistPlans.ENTRY]

export const useGetSearchProfileData = ({
  practice,
  specialist,
  language,
  specialty
}: PracticeOrSpecialistProfileWithLang): SearchProfileData =>
  // todo: fix complexity
  // eslint-disable-next-line complexity
  useMemo(() => {
    const isUnclaimed = unclaimedTags.some((tag) => (practice || specialist)?.plan?.includes(tag))
    const isEntry = entryTags.some((tag) => (practice || specialist)?.plan?.includes(tag))

    const isPractice = !!practice
    const haveVideoConsultation = specialist?.practices?.some(
      (practiceItem) => practiceItem?.slug === FilterType.VIDEO_CONSULTATION
    )
    const slug = specialist?.slug || practice?.slug || ''
    const profileAbbreviation =
      (practice && getAbbreviation({ practice, language })) ||
      (specialist && getAbbreviation({ specialist, language })) ||
      ''

    const profileFullName = removeSpecificSymbols(
      isPractice
        ? practice.name[language]
        : `${specialist?.title?.[language] ?? ''} ${specialist?.fullName[language]}`.trim() || ''
    )

    const keywords = isPractice ? practice.keywords : specialist?.keywords || []
    const id = isPractice ? practice.id : specialist?.id || 0
    const topLevelKeyword = keywords
      ?.filter((keyword) => {
        if (specialty?.type === 'specialty' && specialty.slug[0] === keyword.slug?.[0]) {
          return specialty.slug[0] === keyword.slug[0] && keyword.isTopLevelChild && keyword.keywordType === 'specialty'
        }
        return keyword.isTopLevelChild && keyword.keywordType === 'specialty'
      })
      .map((keyword) => keyword?.practitioner?.[language] || keyword?.name[language])[0]
    const profileAvatarLink = isPractice
      ? practice?.images?.cover || practice?.images?.logo || ''
      : specialist?.images?.logo || ''
    const subKeywords = keywords?.filter((k) => !k.isTopLevelChild) || []
    const procedures = subKeywords.filter((keyword) => keyword.keywordType === 'procedure')

    const yearsOfExperienceValue = getYearsDiff(specialist?.yearsOfExperience || '')
    const contacts = (isPractice && getPracticeContacts({ practiceProfile: practice })) || null

    const latestReview = practice ? practice?.lastReview : specialist?.lastReview

    const latestReviewPractice = specialist?.lastReview?.practice?.name[language] || null
    const profileReviewTotal = isPractice ? practice.reviewsTotal : specialist?.reviewsTotal || 0
    const averageRating = practice ? practice?.averageRating : specialist?.averageRating
    const distance =
      practice?.distance ||
      specialist?.practices.filter((x) => x.slug !== 'video-consultation').sort((a, b) => a.distance - b.distance)?.[0]
        ?.distance ||
      0

    const transformedAddress =
      (specialist?.address &&
        specialist?.type === 'homecare' &&
        transformHomeCareAddressToString(specialist?.address, language)) ||
      (practice?.address && transformAddressToString(practice?.address, language)) ||
      ''

    const practices = specialist?.practices || []
    const {
      practicesWithEmails = [],
      practicesWithPhones = [],
      practicesWithExternalBookingLinks = []
    } = specialist?.practices ? mapSpecialistPracticesContacts(specialist.practices) : {}

    const isHaveSpecialistPracticesContacts =
      !!practicesWithEmails.length || !!practicesWithExternalBookingLinks.length || !!practicesWithPhones.length
    const isHavePracticeContacts =
      !!contacts?.email?.length || !!contacts?.externalBookingLink?.length || !!contacts?.phone?.length
    const isHaveBookingSlots = !!specialist?.practices?.some((practice) => practice.slots?.length)

    const shouldShowContactButton = isHaveSpecialistPracticesContacts || isHavePracticeContacts || isHaveBookingSlots

    const externalId = practice?.externalId || specialist?.externalId || ''

    return {
      id,
      profileAbbreviation,
      profileFullName,
      keywords,
      topLevelKeyword,
      profileAvatarLink: isUnclaimed || isEntry ? '' : profileAvatarLink,
      subKeywords,
      procedures,
      isUnclaimed,
      yearsOfExperienceValue,
      isPractice,
      contacts,
      latestReview,
      latestReviewPractice,
      profileReviewTotal,
      averageRating,
      shouldShowContactButton,
      distance,
      haveVideoConsultation,
      transformedAddress,
      practices,
      practicesWithEmails,
      practicesWithPhones,
      practicesWithExternalBookingLinks,
      slug,
      externalId
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, practice, specialist])

export const useGetContactsData = ({
  specialistData,
  practiceData
}: {
  specialistData?: SpecialistProfile
  practiceData?: PracticeProfile
}): ContactDetailsProfile[] => {
  const { language } = useContext(AppDataContext)
  const { practice: practiceProvider, specialist: specialistProvider } = useContext(ProfileContext)
  const practice = practiceProvider || practiceData
  const specialist = specialistProvider || specialistData
  const practiceContacts = getPracticeContacts({ practiceProfile: practice })
  const externalId = practice?.externalId || specialist?.externalId || ''
  return useMemo((): ContactDetailsProfile[] => {
    const specialistPractices =
      practice || practiceData ? [practice || practiceData] : specialist?.practices || specialistData?.practices || []
    const shouldUsePracticeContacts = specialistPractices
      .map((sp) => (sp as SpecialistPractice).ContactDetails?.every((cd) => !cd.email?.length && !cd.phone?.length))
      .every((x) => x)

    return (
      specialistPractices
        // eslint-disable-next-line complexity
        .map((specialistPractice) => {
          const contacts = getPracticeContacts({
            specialistPractice: specialistPractice as SpecialistPractice,
            shouldUsePracticeContacts
          })
          const email = practice
            ? practiceContacts?.email || ''
            : (specialist?.useSinglePointOfContact && specialist.emails[0]) || contacts?.email || ''
          const phone = practice
            ? practiceContacts?.phone || ''
            : (specialist?.useSinglePointOfContact && specialist.phones[0]) || contacts?.phone || ''
          const slug = specialist ? specialist.slug : practice?.slug
          const bookingSlots = specialistPractice?.slots || []

          return {
            slug,
            id: specialistPractice?.id,
            externalId,
            profileName: practice?.name[language] || specialist?.fullName[language] || '',
            contactName: specialistPractice?.name[language],
            email,
            phone,
            practiceExternalId: specialistPractice?.externalId,
            externalBookingLink: specialist?.useSinglePointOfContact
              ? specialist.externalBookingLink || specialistPractice?.externalBookingLink
              : specialistPractice?.externalBookingLink,
            logo: specialistPractice?.images?.logo || '',
            image: specialistPractice?.images?.images?.[0] || '',
            overallExperience:
              practice?.overallExperience || (specialistPractice as SpecialistPractice).reviews?.overallExperience,
            reviewsTotal: practice?.reviewsTotal || (specialistPractice as SpecialistPractice).reviews?.reviewsTotal,
            workingOpeningHours: specialistPractice?.workingOpeningHours,
            address: specialistPractice?.address,
            premium: specialistPractice?.customFields?.premium,
            bookingSlots
          }
        })
        // skip contacts with all empty values
        .filter((f) => !!f.email || !!f.phone || !!f.externalBookingLink || !!f.bookingSlots.length)
    )
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    practice,
    specialist?.practices,
    specialist?.fullName,
    specialist?.useSinglePointOfContact,
    specialist?.emails,
    specialist?.phones,
    specialist?.externalBookingLink,
    language,
    practiceContacts?.email,
    practiceContacts?.phone
  ])
}
