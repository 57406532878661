import { PracticeProfile, SpecialistProfile } from '../../../types'
import { PropsWithChildren, createContext } from 'react'

export type ProfileState = {
  practice?: PracticeProfile
  specialist?: SpecialistProfile
}

export type ProfileProps = {
  practice?: PracticeProfile
  specialist?: SpecialistProfile
}

const ProfileContext = createContext<ProfileState>({})

const ProfileContextProvider = ({ practice, specialist, children }: PropsWithChildren<ProfileProps>) => (
  <ProfileContext.Provider
    value={{
      practice,
      specialist
    }}
  >
    {children}
  </ProfileContext.Provider>
)

export { ProfileContextProvider, ProfileContext }
