import { SpecialistPractice } from '../types'
import { LANGUAGES } from '../constants/global'

interface Address {
  postcode: Record<string, string>
  street1: Record<string, string>
  street2: Record<string, string>
  city: Record<string, string>
  country: Record<string, string>
}

export const transformAddressToString = (address: Address, language: string) => {
  const { street2, street1, city, country, postcode } = address || {}
  const isGerman = language.includes('de')

  const result = isGerman
    ? [street2?.[language], street1?.[language], postcode?.[language], city?.[language], country?.[language]]
    : [street2?.[language], street1?.[language], city?.[language], country?.[language], postcode?.[language]]

  return result.filter(Boolean).join(', ')
}

export const transformHomeCareAddressToString = (address: Address, language: string) => {
  const { city, country } = address || {}

  const result = [city?.[language], country?.[language]]

  return result.filter(Boolean).join(', ')
}

export const removeVideoConsultation = (practice: SpecialistPractice) => practice.slug !== 'video-consultation'

export const transformAddressToShortString = (address: Address, language: string): string => {
  const { city, postcode } = address || {}

  if (language === LANGUAGES.DE) {
    return [postcode?.[language], city?.[language]].filter((v) => !!v).join(', ')
  }

  return [city?.[language], postcode?.[language]].filter((v) => !!v).join(', ')
}

export const getAddressFromPractices = (
  practices: SpecialistPractice[] | undefined,
  sortByDistance?: boolean,
  hideVideoConsultation: boolean = true
): Address | undefined => {
  const filteredWithAddress = hideVideoConsultation ? practices?.filter(removeVideoConsultation) : practices
  return sortByDistance
    ? filteredWithAddress?.sort((a, b) => a.distance - b.distance)[0]?.address
    : filteredWithAddress?.[0]?.address
}
